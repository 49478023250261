<template>
  <div class="op-category">
    <header>
      <nav class="op-category-tabs">
        <div v-for="item in siteList" :key="item.site_id" :class="{'active': item.site_id == site}" @click="site = item.site_id">{{item.proxy_name}}</div>
      </nav>
      <el-button type="primary" @click="flag = false">编辑</el-button>
    </header>
    <div class="op-category-content" v-loading="loading">
      <section class="box">
        <h2>一级分类</h2>
        <ul>
          <li v-for="item in (stieTree[site] && stieTree[site].firstList || [])" :key="item.cid" class="item">
            <el-checkbox
              v-model="item.is_select"
              :disabled="flag"
              @change="set(item,true)"
            ></el-checkbox>
            <span
              class="word-color"
              :title="item.name"
              :class="{
                red: clickObj.act1 == item.cid,
              }"
              @click="getCategory(site, item, 2)"
              >{{ item.name }}</span
            >
          </li>
        </ul>
      </section>
      <section class="box">
        <h2>二级分类</h2>
        <ul>
          <li v-for="item in (stieTree[site] && stieTree[site].secondList || [])" :key="item.cid" class="item">
            <el-checkbox
              v-model="item.is_select"
              :disabled="flag"
              @change="set(item,true)"
            ></el-checkbox>
            <span
              class="word-color"
              :title="item.name"
              :class="{
                red: clickObj.act2 == item.cid,
              }"
              @click="getCategory(site, item, 3)"
              >{{ item.name }}</span
            >
          </li>
        </ul>
      </section>
      <section v-if="site != 8" class="box">
        <h2>三级分类</h2>
        <ul>
          <li v-for="item in (stieTree[site] && stieTree[site].thirdList || [])" :key="item.cid" class="item">
            <el-checkbox
              v-model="item.is_select"
              :disabled="flag"
              @change="set(item,true)"
            ></el-checkbox>
            <span
              class="word-color"
              :title="item.name"
              >{{ item.name }}</span
            >
          </li>
        </ul>
      </section>
    </div>
    <footer v-if="!flag">
      <el-button type="primary" @click="refuse">取消</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'

export default defineComponent({
  setup(prop,{ root }) {
    const site = ref()
    const siteList = ref([])
    const stieTree = reactive({});
    const ids_add = ref([])
    const ids_cancel = ref([])
    const flag = ref(true)
    const loading = ref(false)
    const params = reactive({
      operation: [],
      site_id: ''
    })
    const clickObj = reactive({
      act1: undefined,
      act2: undefined,
    });
    watch(site,val=>{
      ids_add.value = []
      flag.value = true
      params.site_id = val
      params.operation = []
      getCategory(val,undefined,1)
    })
    const getSite = () => {
      root.$axios.get(`/orderSafe/platformBan/site/list`)
      .then(res => {
        res.data.forEach((item) => {
          item.firstList = [];
          item.secondList = [];
          item.thirdList = [];
          item.children = [];
          stieTree[item.site_id] = item
        });
        siteList.value = res.data
        if(res.data.length>0){
          site.value = res.data[0].site_id
        }
      })
      
    }
    getSite()
    const getCategory = (siteId,p,type,keep) => {
      if(!siteId) return
      root.$axios.get(`/orderSafe/platformBan/category/list`,{
        params: {
          site_id: siteId,
          p_id: p && p.cid || 0
        }
      })
      .then(res => {
        res.data.forEach(item=>{
          item.is_select = (item.ban == 2)
          // if(ids_add.value.includes(item.cid)){
          //   item.is_select = true
          // }
          item.children = [];
          if(p && p.is_select) {
            item.is_select = true
          }
        })
        if(p) {
          p.children = res.data
        }
        if(type == 1) {
          stieTree[siteId].firstList = judge(res.data)
          stieTree[siteId].children = res.data
        }
        if(type == 1 && keep) {
          stieTree[siteId].firstList.forEach(item=>{
            if(clickObj.act1 == item.cid) {
              getCategory(siteId,item,2,keep)
            }
          })
        }
        if(type == 1 && !keep) {
          clickObj.act2 = undefined
        }
        if(type == 2) {
          clickObj.act1 = p.cid
          stieTree[siteId].secondList = judge(res.data)
          stieTree[siteId].thirdList = []
        }
        if(type == 2 && keep) {
          stieTree[siteId].secondList.forEach(item=>{
            if(clickObj.act2 == item.cid) {
              getCategory(siteId,item,3)
            }
          })
        }
        if(type == 3) {
          clickObj.act2 = p.cid
          stieTree[siteId].thirdList = judge(res.data)
        }
      })
      
    }
    const refuse = () => {
      ids_cancel.value = []
      ids_add.value = []
      flag.value = true
      getCategory(site.value,undefined,1, true)
    }
    const save = async () => {
      if(ids_add.value.length == 0 && ids_cancel.value.length == 0) {
          flag.value = true
          return
        }
        const confirm = await root.$goDialog('确定保存吗？')
        if(!confirm) return
        loading.value = true
        root.$axios.post(`/orderSafe/platformBan/category/edit`,params)
        .then(res=>{
          params.operation = []
          loading.value = false
          root.$message.success(res.msg)
          flag.value = true
        })
        .catch(err=>{
          loading.value = false
        })
    }
    const pushIds = (data, parentId) => {
      if(data.is_select && !ids_add.value.includes(data.cid) && !parentId) {
        ids_add.value.push(data.cid)
      }
      if(!data.is_select && ids_add.value.includes(data.cid)){
        ids_add.value.splice(ids_add.value.indexOf(data.cid),1)
      }
      if(parentId && data.is_select && ids_add.value.includes(data.cid)){
        ids_add.value.splice(ids_add.value.indexOf(data.cid),1)
      }
      if(!data.is_select && !ids_cancel.value.includes(data.cid)){
        ids_cancel.value.push(data.cid)
      }
      if(data.is_select && ids_cancel.value.includes(data.cid)){
        ids_cancel.value.splice(ids_cancel.value.indexOf(data.cid),1)
      }
    }
    const judge = (list) => { // 列表选中绑定判断，只判断漏选的
      list.forEach((item) => {
        if (
          item.is_select &&
          !ids_add.value.includes(item.cid)
        ) {
          ids_add.value.push(item.cid);
        }
      });
      return list;
    };
    const selAll = (data, flag) => {
      if (!data.children) return;
      data.children.forEach((item) => {
        item.is_select = flag;
        pushIds(item, data.cid)
        if (item.children) {
          selAll(item, flag);
        }
      });
    }
    const check = (arr, data) => {
      // 子级查找父级
      let list = [];
      if (arr.length == 0 || data.level == 1) {
        if (data.cid) {
          list.unshift(data);
        }
        return list;
      }
      let rev = (childrenArr, id) => {
        for (let i = 0; i < childrenArr.length; i++) {
          let node = childrenArr[i];
          if (node.cid == id) {
            list.unshift(node);
            rev(arr, node.p_id);
            break;
          } else if (node.children) {
            rev(node.children, id);
          }
        }
        return list;
      };
      list = rev(arr, data.cid);
      return list;
    };
    const dis = (data, list) => { // 子类改变时，取消父类选中，并删除父类id的存值
    if(list.length <= 1) return
      const num = list.length - 2
      let flag = true
      list[num].children.forEach(item=>{
        if(!item.is_select) {
          flag = false
        }
        pushIds(item)
      })
      if(flag) { // 如果子类全选中，则只存父id的值,子类id全取消
        list[num].children.forEach(item=>{
          pushIds(item,list[num].cid)
        })
      }
      list[num].is_select = flag
      pushIds(list[num])
      list.splice(num,1)
      dis(data,list)
    }
    const set = async (data)=>{
      await pushIds(data)
      if(data.level < 3) {
        await selAll(data, data.is_select);
      }
      if(data.level > 1) {
        const list = check(stieTree[site.value].children,data)
        dis(data, list)
      }
      params.operation.push({
        cid: data.cid,
        ban: data.is_select ? 2 : 1
      })
    }
    return {
      site,
      siteList,
      stieTree,
      flag,
      clickObj,
      loading,
      set,
      save,
      refuse,
      getCategory
    }
  },
})
</script>
<style lang="scss" scoped>
.op-category{
  display: flex;
  flex-direction: column;
  padding-right: 48px !important;
  padding-bottom: 100px !important;
  header{
    display: flex;
    justify-content: space-between;
  }
  footer{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
}
.op-category-tabs{
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
  &>div{
    flex-shrink: 0;
    max-width: 300px;
    padding: 8px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    &.active{
      background-color: #4f5bff;
      color: #fff;
      border-color: #4f5bff;
    }
  }
}
.op-category-content{
  display: flex;
  height: 100%;
  width: 100%;
  &>section{
    width: 100%;
    height: 100%;
    overflow: auto;
    border: 1px solid #ccc
  }
  h2{
    background-color: #e9e9e9;
    padding: 5px 10px;
    flex-shrink: 0;
    text-align: center;
  }
  .box{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    ul{
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }
  .item{
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px 15px;
  }
}
.el-button{
  border-radius: 5px;
}
</style>